.s-video {
  transform: scale(1.01);
}
/* 
@media (max-width: 1690px) and (min-width: 1680px) {
  .s-video {
    width: 100%;
    margin-top: 40px;
    transform: scale(1.065);
  }
} */

/* @media (max-width: 1330px) and (min-width: 1320px) {
  .s-video {
  
    margin-top: 40px;
   
  }
} */
