.r-wrapper {
  width: 95vw;
  margin-block: 1vw 1vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  margin-left: -3.8vw;
}

.r-mr {
  margin-inline: 6vw;
}

.r-welcome {
  text-align: center;
  margin-block: 1.2vw 1.4vw;
  font-size: 1.6vw;
  font-weight: 600;
}

.r-content {
  padding-inline: 2.4vw 1.8vw;
  margin-block: 2vw 1.5vw;
  font-size: 1.1vw;
}

.register_page {
  width: 32vw;
  /* / / height: 670px; / / */
  border-radius: 2.9vw;
  /* / / padding-top: 1px; / / */
  box-shadow: 0.1vw 0.1vw 0.8vw rgb(69, 69, 109);
}
.r-btn {
  margin-block: 2vw 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.r-btn button {
  padding: 0.8vw 4vw;
  font-size: 1.2vw;
  color: white;
  background-color: rgb(59, 59, 105);
  border-radius: 1.7vw;
  border: none;
  cursor: pointer;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.container-r {
  width: 50vw;
  /* / / margin: 10rem auto; / / */
  /* / / height: 570px; / / */
}

.slide-r > img {
  width: 50vw;
  margin: 0 auto;
}


@media screen and (max-width: 749px) {
  
  .logo {
    width: 18.4vw;
    height: 7.7vw;
  }
    .container-r{
      display: none;
    }
    .register_page {
      width: 65vw;
      padding: 4vw;
      /* / / height: 670px; / / */
      border-radius: 2.9vw;
      /* / / padding-top: 1px; / / */
      box-shadow: 0.1vw 0.1vw 0.8vw rgb(69, 69, 109);
    }
    .r-mr {
      margin-inline: 6vw;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 150vw;
      align-items: center;
  }
  .r-welcome {
    text-align: center;
    margin-block: 1.2vw 1.4vw;
    font-size: 3.6vw;
    font-weight: 600;
  }

  ._form{
    margin-bottom: 4vw;
  }
  
  .btns {
    margin: auto;
    text-align: center;
    width: 38vw;
    border-radius: 1.9vw;
    background-color: rgb(237, 169, 43);
  }
  .btns > button {
    font-size: 3.2vw;
    padding: 1vw 3vw;
    border-radius: 1.9vw;
    background-color: transparent;
    border: none;
    color: black;
  }
  .r-content {
    padding-inline: 2.4vw 1.8vw;
    margin-block: 2vw 2.5vw;
    font-size: 2vw;
  }
  .input_label {
    font-weight: 600;
    font-size: 3vw;
    margin-bottom: 2vw;
  }
  ._form input[type="text"],
input[type="password"] {
  font-size: 3vw;
  margin-bottom: 2vw;
}
.l-subcontent {
  margin-inline: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 1.4vw;
  margin-bottom: 5vw;
}
.name,
.password,
.email {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6vw;
  margin-block: 3.5vw;
}
.check > span {
  font-size: 2.5vw;
}
.l-subcontent a {
  text-decoration: none;
  color: black;
  font-size: 2.5vw;
}
.ch {
  width: 3.1vw;
  height: 3.1vw;
  margin-right: 0.3vw;
  border: 0.1vw solid black;
}
.r-btn button {
  padding: 1vw 5vw;
  font-size: 4.2vw;
  color: white;
  background-color: rgb(59, 59, 105);
  border-radius: 1.7vw;
  border: none;
  cursor: pointer;
  margin-bottom: 1vw;
  margin-top: 3vw;
}
.l-btn span {
  color: gray;
  font-size: 2.5vw;
}
} 