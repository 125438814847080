.q-section2 {
  width: 100%;
  height: 52vw;
}
.q-container {
  width: 100%;
  background: url("../../assets/images/bg.png");
  height: 56vw;
  margin-left: 0.09vw;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.q-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.2vw;
}

.q-details {
  width: 37vw;
  height: 21vw;
  background: url("../../assets/images/leaf_yellow.png");
  border-radius: 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 2.5vw;
}

.q-image {
  width: 20vw;
  /* height: 20.5rem; */
}

.q-details-text {
  /* margin-top: 6.4vw; */
  margin-top: -1vw;
  color: #000;
  font-size: 1.7vw;
  font-weight: 600;
}

.q-details-sub-subtext {
  font-size: 1.15vw;
  margin-top: 0.5vw;
  color: #000;
  width: 32vw;
  /* height: 6.88894rem; */
}

/* // slider //   */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.quality-carousel-container {
  /* margin-top: 2.5vw; */
  width: 70vw;
  /* margin: 5vw auto; */
}

.quality-slide {
  transform: scale(1);
  transition: transform 300ms;
  height: 26vw;
}
.subq-arrow {
  background-color: #fdcf0b;
  width: 3.15vw;
  /* height: 2.5rem; */
  border-radius: 2vw;
  /* padding: 0.6vw; */
  position: absolute;
  cursor: pointer;
  z-index: 10;
  padding-inline: 0.96vw 0.9vw;
  padding-block: 0.89vw 0.7vw;
}

.quality-nexts {
  right: -5vw;
  top: 9vw;
}

.quality-prevs {
  left: -5vw;
  top: 9vw;
}



/* ///// */
.q-flex-res{
  display: none;
}

/* /// */
@media screen and (max-width: 837px) {
  .q-flex-res {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4.2vw;
    flex-direction: column;
  }
  .q-flex{
    display: none;
  }
  .q-details{
    width: 100%;
    text-align: center;
    padding-left: 0%;
    height: 70vw;
    padding: 22px;
  }
  .q-details-text{
    width: 100%;
    font-size: 5vw;
  }
  .q-details-sub-subtext{
    font-size: 4vw;
    width: 100%;
  }
  #q-slider-res{
    height: 140vw;
    width: 100%;
  }

  /* // */
  .q-section2{
    width: 100%;
    height: 159vw;
  }
  .q-container{
    width: 100%;
    background: url("../../assets/images/bg.png");
    height: 170vw;
    margin-left: 0vw;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  /* /// */
  .q-image{
    width: 55vw;
  }


  /* /// */
  .quality-nexts {
    right: -5vw;
    top: 50%;
  }
  
  .quality-prevs {
    left: -5vw;
    top: 50%;
  }
} 
