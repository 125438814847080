.a6-section {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  width: 100%;
}

.a6-header {
  font-size: 4.8vw;
  color: #fdcf0b;
  font-weight: 600;
  text-align: center;
}

.a6-subheader {
  margin-top: -0.7vw;
  font-size: 1.25vw;
  text-align: center;
}

.a6-flex {
  margin-top: -9.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2vw;
}

.a6-about {
  display: flex;
  flex-direction: column;
  width: 60vw;
  margin-top: 6vw;
}

/* .profile1 {
  height: 0vw;
} */

.team-slider {
  margin-bottom: 1.5vw;
  padding: 1vw 1.5vw;
  background-image: linear-gradient(to left, #fdcf0b, #fae278);
}

.head-subinfo {
  font-size: 2.5vw;
  font-weight: 600;
}

.about-head {
  margin-block: 1vw;
  font-size: 1.3vw;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.container23 {
  width: 29vw;
  margin: 6vw auto;
}

.slide23 img {
  margin-top: 5vw;
  width: 29vw;
  height: 36.5vw;
}

.slide23 {
  object-fit: cover;
  transition: transform 300ms;
}

.arrow-a6 {
  padding-inline: 0.8vw;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.next-a6 {
  right: -5vw;
  top: 20vw;
}

.prev-a6 {
  left: -4vw;
  top: 20.2vw;
  transform: rotate(180deg);
}

.head-info {
  /* width: 35vw; */
  margin-top: -3vw;
  font-size: 2.3vw;
  font-weight: 600;
  /* padding-left: 2vw; */
}

.head-subinfo {
  font-size: 1.3vw;
  font-weight: 400;
  /* margin-left: 7.7vw; */
  margin-top: -0.2vw;
}

.f-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.team-profile > .profile_img_11 {
  width: 9vw;
  margin-left: 2vw;
  margin-bottom: 17vw;
}

.profiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-profile {
  z-index: 1;
  text-align: center;
  padding: 3vw;
  border: black;
  box-shadow: 1px 1px 10px rgb(228, 226, 226);
  border-radius: 1.2vw;
}

/* .profileimg {
  width: 90px;
} */

.profile-info-12 {
  margin-inline: 0.3vw;
  width: 12vw;
  margin-top: -12vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.name {
  color: #000;
  font-family: Cambria;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.position {
  color: rgba(9, 9, 9, 0.71);
  margin-top: -1.6vw;
  font-size: 1vw;
  font-weight: 700;
}

.slide45 {
  object-fit: contain;
  transition: transform 300ms;
  height: 26vw;
}

.bottom_img_01 {
  top: 12vw;
  width: 18.7vw;
  height: 4.7vw;
  position: absolute;
}

.img_11 {
  width: 5.5vw;
  height: 2.7vw;
  position: absolute;
  top: 1.9vw;
  margin-left: -10vw;
}

.img_22 {
  width: 5.5vw;
  height: 2.5vw;
  position: absolute;
  top: 9vw;
  margin-left: 10vw;
}


.head-subinfo-res{
  display: none;
}

@media screen and (max-width: 837px) {
  .a6-header {
    font-size: 6.8vw;
    color: #fdcf0b;
    font-weight: 600;
    text-align: center;
}
.a6-subheader {
  margin-top: -0.7vw;
  font-size: 3.25vw;
  text-align: center;
}
.a6-about {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-top: 12vw;
}
.about-head {
  margin-block: 1vw;
  font-size: 2.3vw;
}
.team-profile {
  z-index: 1;
  text-align: center;
  padding: 3vw;
  height: 34vw;
  border: black;
  box-shadow: 1px 1px 10px rgb(228, 226, 226);
  border-radius: 1.2vw;
}

.team-profile > .profile_img_11 {
  width: 15vw;
  margin-left: 2vw;
  margin-bottom: 17vw;
}
.slide45{
  height: 55vw;
}
.bottom_img_01 {
  top: 14vw;
  width: 18.7vw;
  height: 4.7vw;
  position: absolute;
}
.profile-info-12 {
  margin-inline: 0.3vw;
  width: 100%;
  margin-top: -12vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.name,
.password,
.email {
  display: flex;
  font-size: 2.5vw;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6vw;
  margin-block: 1.5vw;
}
.position {
  color: rgba(9, 9, 9, 0.71);
  margin-top: -1.6vw;
  font-size: 1.5vw;
  font-weight: 700;
}
.slide23{
  height: 51vw;
}
#on-jk{
  height: 55vw;
  width: 30vw;
}
.f-name{
height: 68vw;

}
.head-info{
  font-size: 3.5vw;
  width: 60%;
  text-align: center;
  margin-right: 4vw;
}
.head-subinfo-res{
  font-size: 2.5vw;
  width: 95%;
  display: block;
  text-align: center;
  margin-left: 2vw;
}
.head-subinfo{
  display: none;
}
.arrow-a6 {
  padding-inline: 0.8vw;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.next-a6 {
  right: -11vw;
  top: 20vw;
}

.prev-a6 {
  left: -10vw;
  top: 20.2vw;
  transform: rotate(180deg);
}
} 