.ff-container {
  width: 100%;
  background-image: url("../../assets/images/footer_bg.png");
  height: 51vw;
  background-repeat: repeat repeat;
  background-position: center;
  background-size: cover;
  /* margin-bottom: ; */
}

.f-header {
  text-align: center;
  font-size: 2.3vw;
  font-weight: 700;
  padding-top: 3vw;
}

.f-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 7vw;
  width: 72vw;
  position: relative;
  top: 5vw;
  left: 22vw;
}

.f-address-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 7vw;
  width: 72vw;
  position: relative;
  top: 4.5vw;
  left: 21vw;
}

.f-work {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.f-content_header {
  font-weight: 800;
  font-size: 1.37vw;
}

.nav_header,
.work_header,
.contact_header,
.social_header {
  margin-bottom: 1.1vw;
}

.f-link {
  color: black;
  text-decoration: none;
  display: flex;
  font-size: 1.2vw;
  line-height: 2.7vw;
  cursor: pointer;
}

.work_list {
  list-style: none;
}

.work_item {
  font-size: 1.2vw;
  line-height: 2.7vw;
  width: 12vw;
}

.social_icons {
  display: flex;
  align-items: center;
  gap: 1.1vw;
}

.f-address,
.f-number {
  width: 21vw;
  font-size: 1.2vw;
  margin-bottom: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7vw;
}

.ff {
  margin-left: 1.4vw;
}

.copyrights {
  width: 20vw;
  font-size: 1.1vw;
  margin-left: 79.5vw;
  margin-top: 7vw;
}

a {
  color: black;
}


/* //// */

.f-address-res{
  display: none;
}
.f-number-res{
  display: none;
}
.social_icons-res{
  display: none;
}

@media screen and (max-width: 837px) {
  .f-content{
    display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 7vw;
  width: 72vw;
  position: relative;
  flex-wrap: wrap;
  top: 10vw;
  left: 21vw;
  }
  .ff-container {
    width: 100%;
    background-image: url("../../assets/images/footer_bg.png");
    height: 140vw;
    background-repeat: repeat repeat;
    background-position: center;
    background-size: cover;
    background-size: 100% 140vw;
    background-repeat: no-repeat;
    /* margin-bottom: ; */
  }

  .f-header {
    text-align: center;
    font-size: 4vw;
    font-weight: 700;
    padding-top: 7vw;
  }

  /* // */
  .f-content_header {
    font-weight: 800;
    font-size: 4vw;
}
.f-link {
  color: black;
  text-decoration: none;
  display: flex;
  font-size: 3vw;
  line-height: 2.7vw;
  cursor: pointer;
  margin-bottom: 2vw;
}
.work_item {
  font-size: 3vw;
  line-height: 2.7vw;
  width: 30vw;
  margin-bottom: 2vw;
}
.f-address-res{
  width: 65vw;
  font-size: 3vw;
  margin-bottom: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7vw;
}
.f-number-res{
  width: 65vw;
  font-size: 3vw;
  margin-bottom: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7vw;
}
.f-address{
  display: none;
}
.f-number{
  display: none;
}
.social_icons-res {
  display: flex;
  align-items: center;
  gap: 1.1vw;
}
.social_icons {
  display: none
}
.copyrights {
  width: 40vw;
  font-size: 4vw;
  margin-left: 55vw;
  margin-top: 11vw;
}
.f-social{
  margin-left: 15vw;
}
} 