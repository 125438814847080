.c-section {
  width: 100%;
}

/* cart */

.m-cart {
  background-image: url("../../assets/images/hpback.png");
  background-color: rgb(253, 207, 11, 0.9);
  background-repeat: repeat repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* title */

.mc-t {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-title {
  width: 70vw;
}

.mc-title {
  padding-block: 0.2vw;
  color: black;
  margin-block: 3.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2vw;
  font-weight: 600;
  background-color: white;
  /* /width: 100%; */
  border-radius: 0.7vw;
  /* padding: 20px auto; */
}

.cart_container {
  border-radius: 0.7vw;
  background: #fff;
  box-shadow: 0.2vw 0.5vw 1.2vw 0vw #5a522e;
  margin: auto;
  width: 60vw;
  margin-bottom: 3.5vw;
}

.product_img {
  position: relative;
}

.pside1,
.pside2 {
  position: absolute;
  width: 10vw;
}

.pside1 {
  left: -2vw;
  top: 5vw;
  rotate: -5deg;
}

.pside2 {
  transform: scaleX(-1);
  rotate: 5deg;
  right: -2.8vw;
  top: 5.2vw;
}

.product_img > .img222 {
  width: 19vw;
  height: 24vw;
  object-fit: contain;
  filter: drop-shadow(0vw 0.7vw 0.2vw hsl(0deg 0% 0% / 0.4));
}

.add-btn {
  margin-top: 2vw;
}

.add-btn > button {
  padding: 0.5vw 2.2vw;
  font-size: 1.1vw;
  font-weight: 800;
  background-color: #fdcf0b;
  border-radius: 0.2vw;
  border: none;
}

.cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9vw;
  margin-left: 6vw;
}

.product_description {
  height: 18vw;
  width: 25vw;
}

.cart_title {
  font-size: 1.6vw;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.cart_title > span {
  font-size: 1vw;
  margin-left: 0.4vw;
}

.cart_subtitle {
  margin-block: -0.2vw 0.7vw;
  font-size: 1.1vw;
}

.cart_description {
  margin-left: 1.2vw;
}

.cart_description > ._desc {
  line-height: 1.7vw;
  font-size: 1.1vw;
  font-weight: 600;
}

.cart_p {
  margin-top: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: px; */
  font-size: 1.1vw;
  width: 25vw;
  gap: 3vw;
}

.qua {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5vw;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6vw;
  width: 7vw;
}

.quantity > button {
  font-size: 0.8vw;
  padding-block: 0.01vw 0.2vw;
  padding-inline: 0.5vw;
  border-radius: 0.3vw;
  border: 0.1vw solid black;
}

.minus,
.plus {
  font-weight: 900;
}

/* pay */

.pay_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy_btn {
  margin-bottom: 2vw;
  cursor: pointer;
  width: 40vw;
  background-color: rgb(43, 43, 218);
  border: 0.15vw solid white;
  border-radius: 1.5vw;
  text-align: center;
  padding: 0.8vw;
  font-size: 1.8vw;
  font-weight: 400;
  color: white;
}

/* // Border //    */

.total {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.3vw;
}

.border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 5.2vw;
  padding-block: 1.2vw;
  /* height: 1rem; */
  width: 65vw;
  font-size: 1.8vw;
  /* border: 0.2rem solid black; */
}




@media screen and (max-width: 837px) {
  .subp-back {
    background-color: #d9daff;
    margin-block: 1vw;
    display: flex;
    align-items: center;
    margin-top: 13vw;
    justify-content: center;
    gap: 21vw;
    color: #000;
    font-family: Cambria;
    font-size: 3vw;
    font-weight: 700;
    height: 17vw;
    padding-right: 2vw;
}
.c-title {
  width: 81vw;
}
.mc-title {
  padding-block: 0.2vw;
  color: black;
  margin-block: 3.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.2vw;
  font-weight: 600;
  background-color: white;
  border-radius: 0.7vw;
}
.cart_container {
  border-radius: 0.7vw;
  background: #fff;
  box-shadow: 0.2vw 0.5vw 1.2vw 0vw #5a522e;
  margin: auto;
  width: 81vw;
  margin-bottom: 3.5vw;
}
.pside1 {
  left: -2vw;
  top: 15vw;
  rotate: -5deg;
}
.product_img > .img222 {
  width: 35vw;
  height: 45vw;
  object-fit: contain;
  filter: drop-shadow(0vw 0.7vw 0.2vw hsl(0deg 0% 0% / 0.4));
}
.pside2 {
  transform: scaleX(-1);
  rotate: 5deg;
  right: -2vw;
  top: 15vw;
}
.pside1, .pside2 {
  position: absolute;
  width: 14vw;
}

.cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  margin-left: 6vw;
  width: 73vw;
}
.cart_title {
  font-size: 4.6vw;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  margin-top: 5vw;
}
.cart_title > span {
  font-size: 2vw;
  margin-left: 0.4vw;
}
.cart_subtitle {
  margin-block: -0.2vw 0.9vw;
  font-size: 2vw;
  margin-bottom: 2.5vw;
}
.cart_description {
  margin-left: 0vw;
}
.product_description {
  height: 55vw;
  width: 33vw;
}
.cart_description > ._desc {
  line-height: 1.7vw;
  font-size: 2.1vw;
  font-weight: 600;
  margin-right: 2vw;
  width: 100%;

}
._desc li{
  margin-bottom: 1.5vw;
}
.cart_p {
  margin-top: 1.2vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 3.1vw;
  width: 27vw;
  flex-wrap: wrap;
  gap: 2vw;
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6vw;
  width: 17vw;
  height: 4vw;
}
.quantity > button {
  font-size: 2.8vw;
    width: 4vw;
    padding-block: 0.01vw 0.2vw;
    padding-inline: 0.5vw;
    border-radius: 0.3vw;
    border: 0.1vw solid black;
}
.add-btn > button {
  padding: 0.5vw 2.2vw;
  font-size: 2.1vw;
  font-weight: 800;
  background-color: #fdcf0b;
  border-radius: 0.2vw;
  border: none;
  margin-bottom: 1.5vw;
  margin-bottom: 5vw;
}
.border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 5.2vw;
  padding-block: 1.2vw;
  /* height: 1rem; */
  width: 78vw;
  font-size: 3.8vw;
  /* border: 0.2rem solid black; */
}
.overflowcart{
    height: 140vw;
    overflow: scroll;
}
.buy_btn {
  margin-bottom: 3vw;
  cursor: pointer;
  width: 53vw;
  background-color: rgb(43, 43, 218);
  border: 0.15vw solid white;
  border-radius: 1.5vw;
  text-align: center;
  padding: 0.8vw;
  font-size: 3.8vw;
  font-weight: 400;
  color: white;
}
.pay_container {
  display: flex;
  align-items: center;
  height: 16vw;
  margin-bottom: 6vw;
  justify-content: center;
}
} 