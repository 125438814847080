.a5-section {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-bottom: 10vw;
  width: 100%;
}

.a5-header {
  font-size: 2.8vw;
  font-weight: 600;
  text-align: center;
}

.a5-subtitle {
  font-size: 1.25vw;
  text-align: center;
  margin-top: -0.35vw;
}

.sub-div1 {
  text-align: center;
  width: 16vw;
  height: 24vw;
  background-color: white;
  margin-top: 9vw;
  padding-inline: 1.15vw;
  box-shadow: 0.1vw 0.1vw 0.8vw rgb(228, 226, 226);
}

.logo1 {
  margin-block: 1.4vw;
  width: 13.7vw;
}

.logo1 > img {
  width: 4vw;
}

.sub-div1-content {
  font-size: 1.07vw;
  text-align: center;
  height: 13vw;
  width: 13.75vw;
}

.title5 {
  font-size: 1.4vw;
  font-weight: 700;
  margin-bottom: 1vw;
  width: 13.7vw;
}

.boxes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6vw;
  margin-top: 2.5vw;
  background-image: linear-gradient(to right, #fdcf0b, #fae278);
  height: 24vw;
  width: 100%;
}


@media screen and (max-width: 837px) {
  .a5-header {
    font-size: 3.8vw;
    font-weight: 600;
    text-align: center;
}
.a5-subtitle {
  font-size: 2.25vw;
  text-align: center;
  margin-top: -0.35vw;
}


/* ///// */
.boxes{
  display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6vw;
    margin-top: 2.5vw;
    margin-bottom: 2.5vw;
    background-image: linear-gradient(to right, #fdcf0b, #fae278);
    height: 100%;
    width: 100%;
}
.sub-div1 {
  text-align: center;
  width: 40vw;
  margin: 2vw;
  padding: 2vw;
  height: 60vw;
  background-color: white;
  margin-top: 9vw;
  padding-inline: 1.15vw;
  box-shadow: 0.1vw 0.1vw 0.8vw rgb(228, 226, 226);
}
.logo1 {
  margin-block: 1.4vw;
  width: 100%;
}
.logo1 > img {
  width: 12vw;
}
.title5 {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 1vw;
  width: 100%;
}
.sub-div1-content {
  font-size: 3vw;
  text-align: center;
  height: 13vw;
  width: 100%;
}
} 