.login {
  /* / / display: block; / / */
  width: 100%;
}
.l-logo {
  position: relative;
  margin-block: 1.2vw 0;
  margin-left: 1.8vw;
}

.l-mr {
  margin-inline: 6vw;
}

.l-wrapper {
  width: 95vw;
  margin-block: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -3.8vw;
  gap: 1vw;
}

.login_page {
  width: 30vw;
  /* / / height: 670px; / / */
  border-radius: 2.9vw;
  /* / / padding-top: 1px; / / */
  box-shadow: 0.1vw 0.1vw 0.8vw rgb(69, 69, 109);
}

.l_page_content {
  margin-block: 2vw 1vw;
}

.welcome {
  text-align: center;
  margin-block: 1.2vw 1.4vw;
  font-size: 1.6vw;
  font-weight: 600;
}

.btns {
  margin: auto;
  text-align: center;
  width: 25vw;
  border-radius: 1.9vw;
  background-color: rgb(237, 169, 43);
}

.btns > button {
  font-size: 1.2vw;
  padding: 1vw 3vw;
  border-radius: 1.9vw;
  background-color: transparent;
  border: none;
  color: black;
}

.btns button:hover {
  background-color: rgb(42, 42, 151);
  color: white;
}

.l-content {
  padding-inline: 2.4vw 1.8vw;
  margin-block: 2vw 2.5vw;
  font-size: 1.1vw;
}

._form {
  padding-inline: 1.9vw;
}

._form input[type="text"],
input[type="password"] {
  font-size: 1vw;
}

.input_label {
  font-weight: 600;
  font-size: 1.1vw;
}

.input_tp {
  width: 100%;
  padding: 1vw 1.8vw;
  border-radius: 1.8vw;
  border: 0.1vw solid rgb(42, 42, 151);
}
.name,
.password,
.email {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6vw;
  margin-block: 1.5vw;
}

.l-subcontent {
  margin-inline: auto;
  width: 25vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 1.4vw;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ch {
  width: 1.1vw;
  height: 1.1vw;
  margin-right: 0.3vw;
  border: 0.1vw solid black;
}

.check > span {
  font-size: 1.1vw;
}

.l-subcontent a {
  text-decoration: none;
  color: black;
  font-size: 1.1vw;
}

.l-btn {
  margin-block-end: 1.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8vw;
}

.l-btn button {
  padding: 0.7vw 5vw;
  font-size: 1.2vw;
  color: white;
  background-color: rgb(59, 59, 105);
  border-radius: 1.7vw;
  border: none;
  cursor: pointer;
}

.l-btn span {
  color: gray;
  font-size: 1.1vw;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.l_container {
  width: 50vw;
  margin-left: 3vw;
}

.slide > img {
  /* / / height: 30rem; /  */
  width: 50vw;
  margin: 0 auto;
}


@media screen and (max-width: 749px) {
  
  .logo {
    width: 18.4vw;
    height: 7.7vw;
  }
    .l_container{
      display: none;
    }
    .l-mr {
      margin-inline: 6vw;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 150vw;
      align-items: center;
  }
  .welcome {
    text-align: center;
    margin-block: 1.2vw 1.4vw;
    font-size: 3.6vw;
    font-weight: 600;
  }
  .login_page {
    width: 65vw;
    margin-right: 10vw;
    padding: 4vw;
    /* / / height: 670px; / / */
    border-radius: 2.9vw;
    /* / / padding-top: 1px; / / */
    box-shadow: 0.1vw 0.1vw 0.8vw rgb(69, 69, 109);
  }
  .btns {
    margin: auto;
    text-align: center;
    width: 38vw;
    border-radius: 1.9vw;
    background-color: rgb(237, 169, 43);
  }
  .btns > button {
    font-size: 3.2vw;
    padding: 1vw 3vw;
    border-radius: 1.9vw;
    background-color: transparent;
    border: none;
    color: black;
  }
  .l-content {
    padding-inline: 2.4vw 1.8vw;
    margin-block: 2vw 2.5vw;
    font-size: 2vw;
  }
  .input_label {
    font-weight: 600;
    font-size: 3vw;
    margin-bottom: 2vw;
  }
  ._form input[type="text"],
input[type="password"] {
  font-size: 3vw;
  margin-bottom: 2vw;
}
.l-subcontent {
  margin-inline: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 1.4vw;
  margin-bottom: 5vw;
}
.name,
.password,
.email {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6vw;
  margin-block: 3.5vw;
}
.check > span {
  font-size: 2.5vw;
}
.l-subcontent a {
  text-decoration: none;
  color: black;
  font-size: 2.5vw;
}
.ch {
  width: 3.1vw;
  height: 3.1vw;
  margin-right: 0.3vw;
  border: 0.1vw solid black;
}
.l-btn button {
  padding: 1vw 5vw;
  font-size: 4.2vw;
  color: white;
  background-color: rgb(59, 59, 105);
  border-radius: 1.7vw;
  border: none;
  cursor: pointer;
  margin-bottom: 2vw;
}
.l-btn span {
  color: gray;
  font-size: 2.5vw;
}
} 