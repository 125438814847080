/* .container-style {
  width: 100%;
  height: 50vw;
  position: relative;
  overflow: hidden;
} */

.nav-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17vw;
  top: 6.15vw;
  left: 37vw;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4vw;
}

.nav-link {
  color: #313131;
  font-size: 1.2vw;
  font-weight: 600;
  text-decoration: none;
}

.btn {
  font-size: 1.1vw;
  font-weight: 600;
  border: none;
  border-radius: 1.5vw;
  padding-block: 0.4vw 0.5vw;
  padding-inline: 1.7vw;
  text-align: center;
  background-color: #fdcf0b;
  cursor: pointer;
}

.carousel-boult {
  position: absolute;
  top: 97%;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  /* margin-left: -2vw; */
}

.carousel-boult span {
  width: 0.5vw;
  height: 0.5vw;
  margin-left: 0.8vw;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-boult span.active {
  background-color: rgb(255, 255, 255);
  transform: scale(1.2);
}
.carousel-boult span.noactive {
  background-color: rgb(255, 255, 255, 0.4);
}




/* // */

.img{
  display: none;
}
.reslogin{
  display: none;
}
.reslogins{
  display: flex;
}
.icon{
  display: flex;
}
.icon1{
  display: none;
}
.icon2{
  display: none;
}


/* /// */
@media screen and (max-width: 837px) {
    .nav-container {
      position: fixed;
      display: flex;
      background-color: white;
      align-items:unset;
      justify-content: center;
      gap: 0vw;
      width: 100%;
      left: 0vw;
      top: 0vw;
      z-index: 999;
    }
    .img{
      display: block;
    }
    .img img{
      width: 148px;
      margin-left: 18px;
      margin-top: 10px;
    }
    .reslogin{
      display: block;
    }
    .reslogins{
      display: block;
      margin-right: 12px;
      margin-top: 6px;
    
    width: 54px;
    }
    .navabar{
      width: 100%;
      flex-direction: column;
    }
    .reslogin{
      width: 93%;
    }
    .reslogin .btn{
      font-size: 16px;
    margin-bottom: 22px;
    margin-left: 12px;
    width: 80px;
    }
    .reslogin .btn button{

    }
    .nav-items{
      flex-direction: column;
      display: none;
    }
    .nav-link {
      font-size: 16px;
      margin-left: 119px;
    }
    .lo{
      display: none;
    }
    .icon{
      display: flex;
    }
    .icon1{
      display: block;
    }
    .icon2{
      display: none;
    }
    
} 