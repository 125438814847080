/* products */

.sub1 {
  position: relative;
}
.product-container {
  margin-top: 7vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.pr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
}

.img-29 > .product-img {
  /* width: 16vw; */
  height: 21vw;
  margin-inline: 0.2vw auto;
  margin-block-end: -0.4vw;
  object-fit: cover;
  filter: drop-shadow(0.3vw 0.4vw 0.2vw hsl(0deg 0% 0% / 0.4));
}

.sub1-slide {
  margin-bottom: 5vw;
  text-align: center;
  width: 16vw;
}

.sub1-cart-content {
  margin-top: 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-name {
  font-weight: 600;
  font-size: 1.5vw;
}

.product-subtext {
  font-size: 1vw;
  margin-top: -0.2vw;
}

/* leaf */

.leaf2 {
  position: absolute;
  height: 24vw;
  left: -6vw;
  top: 22vw;
  transform: rotate(1deg);
}

.copy-leaf2 {
  position: absolute;
  height: 24vw;
  right: -6vw;
  top: 22vw;
  transform: rotate(1deg);
  transform: scaleX(-1);
}




@media screen and (max-width: 837px) {
  .pr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  flex-wrap: wrap;
}

.img-29 > .product-img {
  width: 40vw;
  height: 40vw;
  margin-inline: 0.2vw auto;
  margin-block-end: -0.4vw;
  object-fit: cover;
  filter: drop-shadow(0.3vw 0.4vw 0.2vw hsl(0deg 0% 0% / 0.4));
}
.sub1-slide {
    margin-bottom: 5vw;
    text-align: center;
    width: 40vw;
}
.product-name {
    font-weight: 600;
    font-size: 3.5vw;
}
.product-subtext {
    font-size: 3vw;
    margin-top: -0.2vw;
}
} 