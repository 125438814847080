.sub1 {
  width: 100%;
}

/* header */

.sub1-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 19vw;
}

.sub1_logo {
  margin-block: 2vw;
  margin-left: 5vw;
}

.sub1-logo {
  width: 13vw;
  height: 4.2vw;
}

.sub1-nav-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}

.sub1-nav-link {
  text-decoration: none;
  color: #000;
  font-family: Cambria;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* sub-header */

.sub1-back {
  background-color: #d9daff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42vw;
  color: #000;
  font-family: Cambria;
  font-size: 1.3vw;
  font-weight: 600;
}

.sub1-content {
  margin-left: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4vw;
}

.sub1-img {
  width: 20vw;
}

.menu-items {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.menu-items > a {
  text-decoration: none;
  color: #000;
}

/* products */

.sub1_container {
  width: 90vw;
  height: 4.3vw;
  margin-block: 3vw;
  /* padding-block: 0; */
  margin-inline: auto;
  background-color: #fdcf0b;
  border-radius: 1vw;
}

.sub1_filter {
  width: 10vw;
  height: 3.3vw;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 0.35vw 0.8vw;
  border-radius: 1vw;
  color: #000;
  font-size: 1.2vw;
  font-weight: 400;
  border: none;
  margin-block: 0.55vw;
  margin-left: 3.69vw;
}

/* .filter-box {
  margin-left: 2vw;
  font-size: 1.2vw;
} */

/* // carousel detail // */

.sub1-price > p {
  margin-right: 0.7vw;
  margin-top: 0.5vw;
  width: 6vw;
  height: 1vw;
  color: #000;
  font-family: Cambria;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.08vw;
}

.sub1-symbole {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub1-order-btn {
  /* width: 11.5vw; */
  margin-top: 1.3vw;
  font-size: 1.1vw;
  font-weight: 900;
  padding-block: 0.5vw 0.6vw;
  padding-inline: 1.8vw;
  border: none;
  background-color: #fdcf0b;
  /* box-shadow: (0px 0px 0.2vw rgba(0, 0, 0, 0.25)); */
  color: black;
  border-radius: 1.5vw;
  cursor: pointer;
}

/* Carousel */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.sub1-carousel-container {
  margin-top: 2.5vw;
  width: 65vw;
  margin-block: 6vw -1vw;
  margin-inline: auto;
}

.img-30 {
  border-radius: 0.3vw;
  width: 17vw;
  height: 21vw;
  padding-block: 0.6vw;
  margin: 0 auto;
}

.img-30 > .sub1-img {
  width: 19.9vw;
  height: 21.5vw;
  margin-block: -0.7vw;
  margin-inline: -1.35vw;
  object-fit: contain;
  filter: drop-shadow(0.3vw 0.4vw 0.15vw hsl(0deg 0% 0% / 0.4));
}

.sub1-arrow {
  background-color: #fdcf0b;
  width: 3vw;
  height: 3vw;
  border-radius: 2vw;
  /* padding: 1.2vw; */
  position: absolute;
  cursor: pointer;
  z-index: 10;
  padding: 0.9vw;
}

.sub1-nexts {
  right: -5.5vw;
  top: 9.5vw;
}

.sub1-prevs {
  left: -5.5vw;
  top: 9.5vw;
}

.sub1-cart-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub1-name {
  text-align: center;
  color: #000;
  font-size: 1.5vw;
  font-weight: 700;
}

.sub1-name > span {
  font-size: 1.1vw;
}

.sub1-subtext {
  margin-top: 0.15vw;
  color: #000;
  text-align: center;
  font-size: 1vw;
  font-weight: 400;
}

/* //leaf img  */

.leaf22 {
  position: absolute;
  height: 28vw;
  left: -5vw;
  top: 33vw;
  transform: rotate(-8deg);
}

.copy-leaf22 {
  position: absolute;
  height: 28vw;
  right: -4.5vw;
  top: 33vw;
  transform: scaleX(-1);
  transform: rotate(45deg);
}


.icon-about{
  display: flex;
}
.icon1-about{
  display: none;
}
.icon2-about{
  display: none;
}

.sub1-price-res{
  display: none;
}



@media screen and (max-width: 837px) {
  .sub1-nav {
    flex-direction: column;
    gap: 0vw;
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: white;
    top: 0vw;
}
  .sub1-logo{
    width: 24vw;
    margin-left: 0px;
    height: 100%;
    margin-top: 3px;
  }
  .sub1_logo{
    width: 80%;
  }
  .sub1-nav-items{
    display: none;
    flex-direction: column;
    margin-bottom: 4vw;
  }
  .sub1-nav-link{
    font-size: 4vw;
    width: 100%;
    text-align: center;
  }
  .logonav{
    display: flex;
    width: 100%;
  }
  .icon-about{
    display: flex;
  }
  .icon1-about{
    margin-top: 2vw;
    display: block;
  }
  .icon2-about{
    margin-top: 2vw;
    display: none;
  }

  /* /// */
  .sub1-back {
    background-color: #d9daff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27vw;
    color: #000;
    font-family: Cambria;
    font-size: 3.3vw;
    font-weight: 600;
    margin-top: 13vw;
    z-index: 999;
}

.sub1-content a{
  font-size: 3vw;
}
.sub1-img {
  width: 27vw;
}




/* /// */

.sub1_container {
  width: 90vw;
    height: 9.3vw;
    margin-block: 3vw;
    /* padding-block: 0; */
    padding: 1vw;
    margin-inline: auto;
    background-color: #fdcf0b;
    border-radius: 1vw;
}
.sub1_filter {
  width: 17vw;
  height: 6.3vw;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 0.35vw 0.8vw;
  border-radius: 1vw;
  color: #000;
  font-size: 3.2vw;
  font-weight: 400;
  border: none;
  margin-block: 0.55vw;
  margin-left: 3.69vw;
}
.sub1-carousel-container {
  margin-top: 2.5vw;
  width: 70vw;
  margin-block: 6vw -1vw;
  margin-inline: auto;
}
.img-30 {
  border-radius: 0.3vw;
  width: 30vw;
  height: 40vw;
  padding-block: 0.6vw;
  margin: 0 auto;
}

.img-30 > .sub1-img {
  width: 35vw;
  height: 40vw;
  margin-block: -0.7vw;
  margin-inline: -1.35vw;
  object-fit: contain;
  filter: drop-shadow(0.3vw 0.4vw 0.15vw hsl(0deg 0% 0% / 0.4));
}
.sub1-nexts-re {
  right: -5.5vw;
  top: 15.5vw;
  height: 6vw;
  width: 6vw;
}

.sub1-prevs-re {
  left: -5.5vw;
  top: 15.5vw;
  height: 6vw;
  width: 6vw;
}
.sub1-name {
  text-align: center;
  color: #000;
  font-size: 4vw;
  font-weight: 700;
}

.sub1-name > span {
  font-size: 3.1vw;
}

.sub1-subtext {
  margin-top: 0.15vw;
  color: #000;
  text-align: center;
  font-size: 2vw;
  font-weight: 400;
}
.sub1-price > p {
  margin-right: 0.7vw;
  margin-top: 0.5vw;
  width: 8vw;
  height: 3vw;
  color: #000;
  font-family: Cambria;
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.08vw;
}
.sub1-order-btn {
  /* width: 11.5vw; */
  margin-top: 1.3vw;
  font-size: 3.1vw;
  font-weight: 900;
  padding-block: 0.5vw 0.6vw;
  padding-inline: 1.8vw;
  border: none;
  background-color: #fdcf0b;
  /* box-shadow: (0px 0px 0.2vw rgba(0, 0, 0, 0.25)); */
  color: black;
  border-radius: 1.5vw;
  cursor: pointer;
}
.sub1-price-res{
  display: block;
}
.sub1-price{
  display: none;
}
} 
