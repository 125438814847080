.ss-contact {
  margin-block: 15vw;
  height: 29vw;
  width: 100%;
  background: #fdcf0b;
  position: relative;
}

.c-main {
  padding-block: 6vw;
  margin-left: -60vw;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
}

.phone {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7vw;
}

.phone > span {
  color: #000;
  font-size: 1.55vw;
  font-weight: 700;
}
.col > span {
  margin-top: 0.2vw;
  margin-left: 9.3vw;
  color: #000;
  font-size: 1.25vw;
  font-weight: 400;
}

.phone-2 {
  margin-left: -1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.6vw;
}

.col-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-s-1 {
  color: #000;
  font-size: 1.55vw;
  font-weight: 700;
}

.col-s-2 {
  width: 20vw;
  margin-top: 0.3vw;
  margin-left: 15vw;
  color: #000;
  font-size: 1.25vw;
}

.social_icons-c {
  margin-top: 2.3vw;
  display: flex;
  gap: 1.2vw;
  align-items: center;
  justify-content: center;
  margin-left: 1.5vw;
}

.c-main2 {
  top: -7.5vw;
  left: 44vw;
  position: absolute;
  width: 46vw;
  height: 43vw;
  background: #fff;
  box-shadow: 0.1vw 0vw 1.2vw 0.1vw rgba(0, 0, 0, 0.15);
  /* backdrop-filter: blur(17.5px); */
}

.c-main2 > img {
  width: 12vw;
  position: absolute;
  margin-left: 35vw;
  margin-top: -1.8vw;
}

.co-title {
  margin-top: 4.5vw;
  margin-left: 3vw;
  color: #000;
  font-size: 2.6vw;
  font-weight: 700;
}

.form12 {
  margin-top: 4vw;
  width: 40vw;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
}

.input-field {
  display: flex;
  flex-direction: column;
}

.input-field > :nth-child(1) {
  padding-left: 0.5vw;
  padding-bottom: 1vw;
  margin-bottom: 4vw;
  border: 0.2vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 1.25vw;
}
.input-field > :nth-child(2) {
  /* padding: 10px 10px; */
  padding-left: 0.5vw;
  padding-bottom: 1vw;
  margin-bottom: 6vw;
  border: 0.2vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 1.25vw;
}

.inptFld {
  padding-left: 1vw;
  margin-bottom: 4.6vw;
  border: 0.1vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 1.25vw;
}

.s-btn > button {
  border: none;
  background-color: #fdcf0b;
  padding-block: 0.6vw 0.7vw;
  padding-inline: 1.8vw;
  border-radius: 2vw;
  color: #000;
  font-size: 1.3vw;
  font-weight: 600;
  cursor: pointer;
}

.social_icons-c-res{
  display: none;
}
.phone-res{
  display: none;
}
.phone-2-res{
  display: none;
}
/* @media screen and (max-width: 837px) and (min-width: 600px) {
  
} */

@media screen and (max-width: 837px) {
  .phone-res > span {
    color: #000;
    font-size: 2vw;
    font-weight: 700;
}
.col > span {
  margin-top: 0.2vw;
  margin-left: 3.3vw;
  color: #000;
  font-size: 1.50vw;
  font-weight: 400;
}
.col-s-1 {
  color: #000;
  font-size: 2vw;
  width: 19vw;
  font-weight: 700;
}
.col-s-2 {
  width: 33vw;
  margin-top: 0.3vw;
  margin-left: 15vw;
  color: #000;
  font-size: 2vw;
}

.social_icons-c-res {
  margin-top: 2.3vw;
  display: flex;
  gap: 1.2vw;
  align-items: center;
  justify-content: center;
  margin-left: 4vw;
}
.social_icons-c {
  display: none;
}
.phone-res {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7vw;
  margin-right: 10vw;
}
.phone {
  display: none
}
.phone-2-res {
  margin-left: -1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.6vw;
}
.phone-2 {
  display: none;
}
.co-title {
  margin-top: 4.5vw;
  margin-left: 3vw;
  color: #000;
  font-size: 3.6vw;
  font-weight: 700;
}
.input-field > :nth-child(1) {
  padding-left: 0.5vw;
  padding-bottom: 1vw;
  margin-bottom: 4vw;
  border: 0.2vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 2.25vw;
}
.input-field > :nth-child(2) {
  /* padding: 10px 10px; */
  padding-left: 0.5vw;
  padding-bottom: 1vw;
  margin-bottom: 6vw;
  border: 0.2vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 2.25vw;
}
.inptFld {
  padding-left: 1vw;
  margin-bottom: 4.6vw;
  border: 0.1vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 2.25vw;
}
.c-main2 {
  top: -7.5vw;
  left: 44vw;
  position: absolute;
  width: 46vw;
  height: 48vw;
  background: #fff;
  box-shadow: 0.1vw 0vw 1.2vw 0.1vw rgba(0, 0, 0, 0.15);
  /* backdrop-filter: blur(17.5px); */
}
.s-btn > button {
  border: none;
  background-color: #fdcf0b;
  padding-block: 0.6vw 0.7vw;
  padding-inline: 1.8vw;
  border-radius: 2vw;
  color: #000;
  font-size: 2.3vw;
  font-weight: 600;
  cursor: pointer;
}
} 

@media screen and (max-width: 423px) {
  .c-main{
    display: none;
  }

  .ss-contact {
    margin-block: 15vw;
    height: 65vw;
    width: 100%;
    background: #fdcf0b;
    position: relative;
  }
.c-main2 {
    top: -7.5vw;
    left: 12vw;
    position: absolute;
    width: 75vw;
    height: 80vw;
    background: #fff;
    box-shadow: 0.1vw 0vw 1.2vw 0.1vw rgba(0, 0, 0, 0.15);
    /* backdrop-filter: blur(17.5px); */
}
.form12{
  width: 90%;
}
.co-title {
  margin-top: 4.5vw;
  margin-left: 0vw;
  color: #000;
  text-align: center;
  font-size: 5.6vw;
  margin-bottom: 5vw;
  font-weight: 700;
}
.input-field > :nth-child(1) {
  padding-left: 0.5vw;
  padding-bottom: 1vw;
  margin-bottom: 10vw;
  border: 0.2vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 3.25vw;
}
.input-field > :nth-child(2) {
  /* padding: 10px 10px; */
  padding-left: 0.5vw;
  padding-bottom: 1vw;
  margin-bottom: 10vw;
  border: 0.2vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 3.25vw;
}
.inptFld {
  padding-left: 1vw;
  margin-bottom: 9.6vw;
  border: 0.1vw solid black;
  border-width: 0vw 0vw 0.2vw 0vw;
  font-size: 3.25vw;
}

.s-btn{
  width: 1005;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-btn > button {
  border: none;
  background-color: #fdcf0b;
  padding-block: 0.6vw 0.7vw;
  padding-inline: 1.8vw;
  border-radius: 2vw;
  color: #000;
  font-size: 4.3vw;
  font-weight: 600;
  cursor: pointer;
}
} 