/* // section //   */

.cc-section {
  width: 80vw;
  margin-inline: auto;
}

/* // title //   */

.client-title {
  margin-block: 1vw 5vw;
}
.cc-title {
  font-size: 3vw;
  font-weight: 600;
  text-align: center;
}

.crown {
  position: absolute;
  width: 4vw;
  top: -3vw;
  left: 22.6vw;
}

/* // client carousel //   */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.cc-container {
  width: 50vw;
  /* height: 8.3125rem; */
  position: relative;
  margin-inline: auto;
  border: 0.2vw #fdcf0b;
  border-style: dashed;
}

.client-slide {
  padding-block: 1vw;
}

.client-slide > .cc-img {
  width: 12vw;
  /* height: 4.625rem; */
  margin-inline: auto;
}

.c-arrow {
  background-color: #fdcf0b;
  border-radius: 2vw;
  position: absolute;
  cursor: pointer;
  padding-inline: 0.8vw 0.75vw;
  padding-block: 0.65vw 0.55vw;
  z-index: 10;
}

.c-next {
  right: -5.5vw;
  top: 2.5vw;
}

.c-prev {
  left: -5.5vw;
  top: 2.5vw;
}

/* //details carousel //   */

/* .cc-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vw;
  position: relative;
} */

.c-arrow1 {
  background-color: #fdcf0b;
  border-radius: 2vw;
  position: absolute;
  cursor: pointer;
  padding-inline: 0.8vw 0.75vw;
  padding-block: 0.6vw 0.5vw;
  z-index: 10;
}

.c-next1 {
  right: 7vw;
  top: 16vw;
  size:1.09vw
}

.c-prev1 {
  left: 7.2vw;
  top: 16vw;
  size:1.09vw
}

.cc-details {
  margin-top: 4vw;
  margin-inline: auto;
  width: 62vw;
  height: 25vw;
  background: url("../../assets/images/bg_clients.png");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  margin-bottom: 6vw;
  position: relative;
}

.cc-details > .client-img {
  width: 10vw;
  position: absolute;
  top: 9.5vw;
  left: 35.5vw;
}

.feedback {
  top: 0.6vw;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 26vw;
}

.feedback-title {
  margin-top: 2vw;
  font-size: 2vw;
  font-weight: 600;
}

.feedback-text {
  text-align: center;
  margin-top: 0.6vw;
  width: 37vw;
  font-size: 1.15vw;
  font-weight: 500;
}

.qu1 {
  position: absolute;
  width: 3.5vw;
  left: 28.8vw;
  top: 1.9vw;
}

/* .qu2 {
  position: absolute;
  width: 6vw; 
  right: 20vw;
  top: 10vw;
} */

.stars {
  width: 13vw;
  position: absolute;
  bottom: 2vw;
  margin-left: 34.1vw;
}




@media screen and (max-width: 837px) {
  .cc-title {
    font-size: 6vw;
    font-weight: 600;
    text-align: center;
  }  
  .cc-container {
    width: 100vw;
    /* height: 8.3125rem; */
    position: relative;
    margin-inline: auto;
    border: 0.2vw #fdcf0b;
    border-style: dashed;
  }  
  .client-slide > .cc-img {
    width: 18vw;
  }


  /* // */

  .cc-section{
    width: 100vw;
    height: auto;
  }
  .cc-details{
    margin-top: 8vw;
  margin-inline: auto;
  width: 62vw;
  height: 70vw;
  background: url("../../assets/images/bg_clients.png");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  margin-bottom: 6vw;
  background-size: 90% 70vw;
  position: relative;
  }

  .cc-details > .client-img {
    width: 20vw;
    position: absolute;
    top: 30.5vw;
    left: 40vw;
  }
  
  .feedback {
    top: 2.5vw;
    width: 55vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 25vw;
  }
  
  .feedback-title {
    margin-top: 2vw;
    font-size: 5vw;
    font-weight: 600;
  }
  
  .feedback-text {
    text-align: center;
    margin-top: 0.6vw;
    width: 50vw;
    font-size: 3.15vw;
    font-weight: 500;
  }
  
  .qu1 {
    position: absolute;
    width: 5vw;
    left: 23vw;
    top: 1.9vw;
  }
  
  /* .qu2 {
    position: absolute;
    width: 6vw; 
    right: 20vw;
    top: 10vw;
  } */
  
  .stars {
    width: 25vw;
    position: absolute;
    bottom: 4vw;
    margin-left: 37vw;
  }
  

  .c-next1 {
    right: 7vw;
    top: 46vw;
    size:2.09vw
    
  }
  
  .c-prev1 {
    left: 7.2vw;
    top: 45vw;
    size:1.09vw
  }
} 
