/* / // / */

.a4-section1 {
  margin-block: 2vw 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* / gap: 1vw; / */
  margin-inline: 9.8vw;
}

.a4-info1 {
  margin-top: 2vw;
}
.a4-info2 {
  margin-top: 2vw;
}

.a4-sub-title1 {
  font-weight: 500;
  font-size: 1.5vw;
}

.a4-main-title1 {
  font-size: 2vw;
  font-weight: 600;
  margin-top: 0.3vw;
}

.main4-subtitle1 {
  font-size: 1.5vw;
  margin-top: -0.2vw;
}

.a4-sub-text1 {
  margin-block: 0.8vw 1vw;
  font-size: 1.1vw;
  text-align: justify;
  width: 40vw;
}

.a4-sub-text2 {
  margin-block: 0.7vw 1vw;
  font-size: 1.1vw;
  width: 40vw;
  text-align: justify;
}

.a4-img1 {
  object-fit: contain;
}

.dal4_img1 {
  width: 38vw;
}
.dal4_img2 {
  width: 36vw;
  margin-left: -2vw;
}

.a4-section2 {
  margin-top: -2vw;
  margin-inline: 10vw;
  margin-bottom: 7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.swara {
  margin-top: 1vw;
  width: 100%;
  height: 51vw;
  background: url("../../assets/images/svara.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
}


.a4-section1-res{
  display: none;
}



@media screen and (max-width: 837px) {

  .a4-section1-res {
    margin-block: 2vw 4vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    /* / gap: 1vw; / */
    margin-inline: 9.8vw;
  }
  .a4-section2 {
    margin-top: -2vw;
    margin-inline: 10vw;
    margin-bottom: 7vw;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  .a4-section1{
    display: none;
  }
  .a4-img1 {
    object-fit: contain;
    width: 100%;
  }
  .dal4_img1 {
    width: 60vw;
}
  .a4-info1 {
    margin-top: 2vw;
    width: 100%;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
  }
  .a4-sub-title1 {
    font-weight: 500;
    font-size: 2.5vw;
  }
  
  .a4-main-title1 {
    font-size: 5vw;
    font-weight: 600;
    margin-top: 0.3vw;
  }
  
  .main4-subtitle1 {
    font-size: 2.5vw;
    margin-top: -0.2vw;
  }
  
  .a4-sub-text1 {
    margin-block: 0.8vw 1vw;
    font-size: 2.1vw;
    text-align: justify;
    width: 60vw;
    text-align: end;
}
  .dal4_img2 {
    width: 60vw;
}
.a4-info2 {
  margin-top: 2vw;
  width: 100%;
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items:flex-end;
}
.a4-sub-title2 {
  font-weight: 500;
  font-size: 2.5vw;
}

.a4-main-title2 {
  font-size: 5vw;
  font-weight: 600;
  margin-top: 0.3vw;
}

.main4-subtitle2 {
  font-size: 2.5vw;
  margin-top: -0.2vw;
}

.a4-sub-text2 {
  margin-block: 0.8vw 1vw;
  font-size: 2.1vw;
  text-align: justify;
  width: 60vw;
  text-align: end;
}
} 