/* .s-container {
  padding-block: 30px;
  background-image: url("../../assets/images/hpback.png");
  background-color: rgb(253, 207, 11, 0.9);
  background-repeat: repeat repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
} */

/* // Header // */

/* .p-head {
  display: flex;
  text-align: center;
  justify-content: center;
}
.p-header {
  width: 80%;
  color: black;
  margin-block: 50px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 600;
  background-color: white;
  border-radius: 40px;
  padding-block: 8px;
}

.pay {
  display: flex;
  text-align: center;
  justify-content: center;
}

.p-details {
  padding-block: 20px;
  width: 60%;

  border-radius: 20px;
  padding-top: 1px;
  background-color: white;
  box-shadow: 2px 2px 30px rgb(69, 69, 109);
}

.p-form {
  padding-left: 30px;
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  );
}

.p-subdetails {
  margin-block: 10px;
  font-size: 1.5rem;
}

.p-subdetails > input {
  padding: 6px;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
  margin-left: 70px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */

/* // Form    */

.login_page1 {
  width: 35vw;
  /* / / height: 670px; / / */
  border-radius: 2.9vw;
  /* / / padding-top: 1px; / / */
  box-shadow: 0.1vw 0.1vw 0.8vw rgb(69, 69, 109);
}

._form11 {
  display: none;
}

.payment-method {
  margin-top: 1vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
}

.pay-text {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.payment-options {
  display: flex;
  align-items: center;
  gap: 3vw;
  /* margin-top: 0.19vw; */
}
.payment-option {
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-option label {
  margin-left: 0.6vw;
}

.p-btn {
  margin-top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-btn button {
  padding: 0.9vw 6vw;
  font-size: 1.3vw;
  color: white;
  background-color: rgb(59, 59, 105);
  border-radius: 1.7vw;
  border: none;
  cursor: pointer;
}




@media screen and (max-width: 749px) {
  .l-wrapper {
    width: 95vw;
    margin-block: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -11vw;
    gap: 1vw;
}
  .login_page1 {
    width: 75vw;
    border-radius: 2.9vw;
    box-shadow: 0.1vw 0.1vw 0.8vw rgb(69, 69, 109);
}
.pay-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  display: flex;
    font-size: 3vw;
}
.p-btn button {
  padding: 0.9vw 6vw;
  font-size: 3.3vw;
  color: white;
  background-color: rgb(59, 59, 105);
  border-radius: 1.7vw;
  border: none;
  cursor: pointer;
}
.payment-method {
  margin-top: 1vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
  margin-bottom: 5vw;
}
} 