* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logo {
  width: 14.4vw;
  height: 4.7vw;
}
