.a-section-mr {
  margin-left: 13vw;
  position: relative;
}

.a-section-btn {
  margin-inline: 10vw;
}

.center-mr {
  margin-inline: 14.3vw;
}

.main-section {
  width: 100%;
  overflow: hidden;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.a-section1 {
  margin-block: 3vw 9vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.a-info1 {
  margin-top: 7vw;
}
.a-sub-title1 {
  font-weight: 500;
  font-size: 1.5vw;
  margin-bottom: 0.3vw;
}

.a-main-title1 {
  /* / margin-block-start: 10px; / */
  font-size: 2vw;
  font-weight: 600;
}

.main-subtitle1 {
  margin-top: -0.2vw;
  font-size: 1.5vw;
}

.a-sub-text1 {
  margin-block: 1.1vw 3vw;
  font-size: 1.1vw;
  text-align: justify;
  width: 40vw;
}

.a-button1 {
  cursor: pointer;
  border: none;
  border-radius: 2vw;
  padding: 0.8vw 2vw;
  font-size: 1.1vw;
  font-weight: 600;
  background-color: rgb(244, 162, 10);
}

.a-img1 {
  width: 55vw;
  height: 28vw;
  object-fit: cover;
}

.dal_img1 {
  width: 60vw;
  height: 39vw;
  margin-left: 4vw;
}

/* / Carousel / */

.a_head1 {
  font-size: 5vw;
  font-weight: 600;
  color: #fdcf0b;
  margin-bottom: -1vw;
}

.a_head1 > p {
  width: 100%;
  text-align: center;
}

.s_container {
  width: 80vw;
  margin: 2.2vw auto;
}

.slide1 > .a_img {
  width: 27vw;
  margin: 0 auto;
  filter: drop-shadow(0.35vw 0.45vw 0.2vw hsl(0deg 0% 0% / 0.4));
}

.slide1 {
  transform: scale(0.6);
  transition: transform 300ms;
  height: 39vw;
}

/* .circle {
  position: absolute;
  width: 20vw;
  padding: 13vw;
  border-radius: 50%;
  background: #fdcf0b;
  border: none;
  left: 36.5vw;
  top: 17vw;
} */

.activeSlide {
  transform: scale(1.2);
  opacity: 1;
  z-index: 1;
}

.p-arrow11 {
  background: #fdcf0b;
  border-radius: 2vw;
  padding-inline: 1.05vw;
  padding-block: 0.99vw 0.9vw;
  height: 3.3vw;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.p-next1 {
  right: -2vw;
  top: 19vw;
}

.p-prev1 {
  left: -2vw;
  top: 19vw;
}

.cart-content {
  height: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4.3vw;
  margin-left: 0.8vw;
}

.p-name1 {
  width: auto;
  text-align: center;
  font-weight: 600;
  font-size: 2.2vw;
}

.p-subt1 {
  width: auto;
  text-align: center;
  font-size: 1vw;
  margin-top: -0.1vw;
}

.bgcolor {
  position: relative;
  padding-top: 6vw;
  background: linear-gradient(
    rgb(235, 217, 135, 0.5),
    rgb(250, 250, 249),
    rgb(235, 217, 135, 0.5)
  );
}

.sideimg {
  position: absolute;
  top: -10vw;
}

.sideimg > img {
  width: 12vw;
}

.o-btn {
  margin-top: -0.5vw;
  text-align: center;
}

.o-btn > button {
  cursor: pointer;
  background-color: #fdcf0b;
  font-size: 1.3vw;
  border: none;
  border-radius: 2vw;
  padding: 0.8vw 2.3vw;
  font-weight: 600;
}

/* / /// quality //////  / */

.center {
  position: relative;
  margin-top: 2vw;
  padding-block: 5.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-footer {
  padding-inline: 3vw;
  border-radius: 1vw;
  background: url("../../assets/images/hpback.png");
  filter: drop-shadow(0.4vw 0.5vw 0.2vw hsl(0deg 0% 0% / 0.4));
  background-color: rgb(253, 207, 11);
  width: 70vw;
  height: 30vw;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brands {
  margin-left: 3vw;
}

.brands > img {
  padding: 0.5vw;
  background-color: #fff;
  box-shadow: 0px 0px 1.1vw rgba(0, 0, 0, 0.25);
  width: 8.5vw;
  height: 5.5vw;
  border-radius: 0.6vw;
}

.bgf-header {
  height: 20vw;
  width: 35vw;
  text-align: justify;
  margin-top: 1.6vw;
}

.hd {
  color: #000;
  font-family: Cambria;
  font-size: 1.9vw;
  font-weight: 600;
  /* / line-height: 13vw; / */
  margin-bottom: 1vw;
  margin-left: 1.7vw;
}

.q-list {
  margin-left: 1.8vw;
  /* / height: 20vw; / */
}

.q-list > li {
  list-style-type: none;
}

li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
}

p {
  width: 30vw;
}

.circle-1,
.circle-2,
.circle-3,
.circle-4 {
  width: 0.46vw;
  height: 0.47vw;
  background-color: #000;
  border: none;
  border-radius: 50%;
}

.circle-1,
.circle-2,
.circle-3 {
  margin-top: -2.4vw;
}
.circle-4 {
  margin-top: -1.05vw;
}

.sub-hd {
  font-size: 1.15vw;
  font-weight: 500;
  /* / line-height: 2vw; / */
  margin-bottom: 1.2vw;
  text-align: left;
}

.brands {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8vw;
}

.sideimg2 {
  position: absolute;
  top: 26vw;
  /* / transform: rotate(-15deg); / */
  /* / left: 1.5vw; / */
}

.sideimg3 {
  position: absolute;
  top: 22vw;
  right: 0;
  /* / transform: rotate(10deg); / */
}
.sideimg2 > img {
  height: 45vw;
  opacity: 0.3;
}
.sideimg3 > img {
  height: 48vw;
  opacity: 0.3;
}

.pside2222 {
  position: absolute;
  top: -16.5vw;
  left: -8vw;
  width: 20vw;
  opacity: 0.5;
  transform: rotate(265deg);
}




/* //// */
.a-section1-res{
  display: none;
}
.bg-footer-res{
  display: none;
}


@media screen and (max-width: 837px) {
  .a-section-mr{
    position: static;
  }
  .bgcolor{
    position: static;
  }
  .sideimg{
    position: relative;
    top: -16vw;
    display: none;
  }

  /* // */
  .a-section1-res{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 81%;
  }
 .a-section1-res .a-img1{
  width: 64vw;
  height: 51vw;
  object-fit: cover;
  }
 .a-section1-res .a-img1 .dal_img1{
  width: 87vw;
    height: 62vw;
    margin-left: -3vw;
  }

  .a-section1-res .a-info1{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 11vw
  }
  .a-sub-title1 {
    font-weight: 500;
    font-size: 2.5vw;
    margin-bottom: 0.3vw;
    margin-bottom: 2vw;
  }
  
  .a-main-title1 {
    /* / margin-block-start: 10px; / */
    font-size: 4vw;
    font-weight: 600;
    margin-bottom: 2vw;
  }
  .a-sub-text1{
    margin-block: 1.1vw 3vw;
    font-size: 3vw;
    text-align: center;
    width: 85vw;
    margin-bottom: 3vw;
  }
  
  .main-subtitle1 {
    margin-top: -0.2vw;
    font-size: 3.5vw;
    margin-bottom: 2vw;
  }

  .a-button1{
    margin-bottom: 10vw;
    font-size: 3vw;
  }
  /* // */


  /* // */
  .a_head1{
    font-size: 6vw;
  }
  /*  */

  .a-section1{
    display: none;
  }


  /* ///product slider4 */
  .s_container{
    width: 60vw;
    height: 85vw;
  }
  .cart-content{
    height: 18vw;
  }
  .p-name1{
    font-size: 5vw;
  }
  .p-subt1{
    font-size: 3vw;
  }
  .slide1 > .a_img {
    width:50vw ;
    height: 60vw;
  }
  #pp{
    height: 90vw;
    width: 66vw;
  }
  .p-arrow11{
    display: none;
  }

  /* /// */
  .a-section-btn{

  }
  .o-btn{

  }
  .o-btn button{
    font-size: 4vw;
  }
  /*  */




  /* ////// */
  .bg-footer{
    display: none;
  }
  .bg-footer-res{
    padding-inline: 3vw;
  border-radius: 1vw;
  background: url("../../assets/images/hpback.png");
  filter: drop-shadow(0.4vw 0.5vw 0.2vw hsl(0deg 0% 0% / 0.4));
  background-color: rgb(253, 207, 11);
  width: 90vw;
  height: 160vw;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  padding: 4vw;
  }


  .brands {
    margin-left: 3vw;
    width: 75vw;
    margin-bottom: 6vw;
  }
  
  .brands > img {
    padding: 0.5vw;
    background-color: #fff;
    box-shadow: 0px 0px 1.1vw rgba(0, 0, 0, 0.25);
    width: 19vw;
    height: 17vw;
    border-radius: 0.6vw;
  }
  
  .bgf-header {
    height: 20vw;
    width: 75vw;
    text-align: justify;
    margin-top: 1.6vw;
  }
  
  .hd {
    color: #000;
    font-family: Cambria;
    font-size: 9vw;
    font-weight: 600;
    /* / line-height: 13vw; / */
    margin-bottom: 1vw;
    margin-left: 1.7vw;
    margin-bottom: 4vw;
  }
  
  .q-list {
    margin-left: 1.8vw;
    width: 100%;
    /* / height: 20vw; / */
  }
  
  .q-list > li {
    list-style-type: none;
    width: 90vw;
  }
  
  li {
    display: flex;
    align-items:baseline;
    justify-content: flex-start;
    gap: 0.5vw;
  }
  
  p {
    width: 30vw;
  }
  
  .circle-1,
  .circle-2,
  .circle-3,
  .circle-4 {
    width: 1.46vw;
    height: 1.47vw;
    background-color: #000;
    border: none;
    border-radius: 50%;
  }
  
  .circle-1,
  .circle-2,
  .circle-3 {
    margin-top: -2.4vw;
  }
  .circle-4 {
    margin-top: -1.05vw;
  }
  
  .sub-hd {
    font-size: 4vw;
    width: 66vw;
    font-weight: 500;
    /* / line-height: 2vw; / */
    margin-bottom: 1.2vw;
    text-align: left;
    margin-bottom: 4vw;
  }
  
  .brands {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.8vw;
  }
  
  .sideimg2 {
    position: absolute;
    top: 26vw;
    /* / transform: rotate(-15deg); / */
    /* / left: 1.5vw; / */
  }
  
  .sideimg3 {
    position: absolute;
    top: 22vw;
    right: 0;
    /* / transform: rotate(10deg); / */
  }
  .sideimg2 > img {
    height: 45vw;
    opacity: 0.3;
  }
  .sideimg3 > img {
    height: 48vw;
    opacity: 0.3;
  }
  
  .pside2222 {
    position: absolute;
    top: -16.5vw;
    left: -8vw;
    width: 20vw;
    opacity: 0.5;
    transform: rotate(265deg);
  }
  
  
  
  /* //// */
} 